import slick from 'slick-carousel';

export default function initCarouselMobile(){
  if ($(window).width() < 768) {
    const carousel = document.querySelectorAll('[data-carouselmobile]');
    if (carousel.length > 0) {
      carousel.forEach( (el) => {
        let show = $(el).data('show');
        let slide = $(el).data('slide');
        let arrows = $(el).data('arrows');
        let dots = $(el).data('dots');
        let center = $(el).data('center');
        let centerPadding = $(el).data('centerPadding');
        let infinite = $(el).data('infinite');
        let speed = $(el).data('speed');
        let drag = $(el).data('drag');
        let autoplay = $(el).data('autoplay');
        let autoplaySpeed = $(el).data('autoplaySpeed');
  
        $(el).slick({
          slidesToShow: (show !== '') ? show : 1,
          slidesToScroll: (slide !== '') ? slide : 1,
          arrows: (arrows === false) ? arrows : true,
          dots: (dots === true) ? dots : false,
          centerMode: (center === true) ? center : false,
          centerPadding: (centerPadding !== '') ? centerPadding : '0px',
          infinite: (infinite === false) ? infinite : true,
          speed: (speed !== '') ? speed : 300,
          prevArrow: '<button class="slick-prev"><i class="fas fa-angle-left"></i></button>',
          nextArrow: '<button class="slick-next"><i class="fas fa-angle-right"></i></button>',
          draggable: (drag !== '') ? drag : true,
          autoplay: (autoplay !== '') ? autoplay : true,
          autoplaySpeed: (autoplaySpeed !== '') ? autoplaySpeed : 2000
        });
      });
    }
  }
}